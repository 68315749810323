import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ProgressBar from "../../components/v2/ProgressBar";
import CountriesModal from "../../components/CountriesModal";

import {
  selectPaymentMethod,
  selectPricePoint,
  loadPaymentOptions,
  loadPaymentOptionsV2,
  loadPaymentOptionsPrepaidInline,
  loadPaymentOptionsPrepaidInlineV2,
  confirmPricePointPurchase,
  confirmPricePointPurchaseV2,
  getPaymentOptionsView,
  getOptionsLoadingState,
  getStorefront,
  getMinVirtualAmount,
  getCheckoutUrl,
  getSelectedPaymentMethodId,
  getStorefrontCountries,
  getAllowCountryChange,
  getTaxDisclaimer,
  getSelectedPricePointId,
  getPurchaseCurrency
} from "../../redux/modules/paymentOptions";
import { getCurrentCountry } from "../../redux/modules/user";
import {
  getSessionToken,
  getSessionIsKorea,
  getSessionUseApiV2,
  getSessionStoreCode,
  getSessionPricePointId,
  initSessionPricePointId
} from "../../redux/modules/session";
import CountryChangeList from "../../components/CountryChangeList";

import { getPurchaseContext, loadPurchaseContextIfNeeded } from "../../redux/modules/purchaseContext";
import PricePointView from "./PricePointView";
import { getVcPricePointsForPaymentMethod } from "../../utils/pricePoint";

class BundleSelection extends React.Component {
  state = {
    countriesModalOpen: false,
    attemptedPurchase: false
  };

  componentDidMount() {
    if (this.props.useApiV2) {
      this.props.loadPaymentOptionsV2();
    } else {
      this.props.loadPaymentOptions(this.props.currentCountry);
    }

    this.props.loadPurchaseContextIfNeeded();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.currentCountry.name && !nextProps.loading) {
      this.setState({
        countriesModalOpen: true
      });
    }
  }

  onPricePointSelect = (id) => {
    this.props.selectPricePoint(id);
  };

  getDoubleBonusVC = (paymentMethodId) => {
    return this.props.paymentOptions.find((option) => option.uniquePaymentMethodId === paymentMethodId)
      .hasDoubleBonusVc;
  };

  attemptStartPurchase = () => {
    this.setState({ attemptedPurchase: true });
  };

  onContinueClick = () => {
    // also persist the pricepoint in session storage for when we redirect to the checkout page and back to the payment method selection page
    const { selectedPricePointId } = this.props;
    this.props.initSessionPricePointId(selectedPricePointId);
    const pricePointList = getVcPricePointsForPaymentMethod(
      this.props.paymentOptions,
      this.props.selectedPaymentMethodId
    );
    const pricePointTier = pricePointList.findIndex((pricePoint) => pricePoint.id === selectedPricePointId) + 1;
    sessionStorage.setItem("pricePointTier", pricePointTier);

    this.context.router.history.push("/payment-method-selection");
  };

  renderPricePointView = () => {
    return (
      <PricePointView onPricePointClick={this.onPricePointSelect} attemptStartPurchase={this.attemptStartPurchase} />
    );
  };

  renderNoPaymentOptionsAvailable = () => (
    <div className="container no-payment-options fade-in">
      {this.renderDetailsAndCountryOptions()}
      <h3 className="no-payment-options-text">
        <FormattedMessage id="no_payment_methods" defaultMessage="No payment methods currently available." />
      </h3>
    </div>
  );

  renderContinueLinks() {
    return (
      <div className="next-step-links">
        <button className="btn btn-primary fist-bump-sm" onClick={this.onContinueClick} disabled={false}>
          <FormattedMessage id="next_step" defaultMessage="Next Step" />
        </button>
        <Link
          className="prepaid-link"
          to={{
            pathname: "/prepaid"
          }}
        >
          <FormattedMessage id="have_a_code" defaultMessage="Have a Code?" />
        </Link>
      </div>
    );
  }

  renderBalance() {
    const currentBalance = this.props.purchaseContext?.virtualCurrencyWalletBalance;
    return (
      <div className="current-balance">
        <FormattedMessage id="current_balance" defaultMessage="Current Balance" />
        {currentBalance != null ? (
          <>
            <span className="vc-icon" />
            <span className="current-balance-amount">
              {currentBalance} {this.props.purchaseCurrency}
            </span>
          </>
        ) : (
          <div className="loading-spinner" />
        )}
      </div>
    );
  }

  onChangeCountry = (country) => {
    if (this.props.useApiV2) {
      this.props.loadPaymentOptionsV2(country);
    } else {
      this.props.loadPaymentOptions(country);
    }
    this.setState({ countriesModalOpen: false });
  };

  toggleCountriesModalOpen = () => {
    this.setState(prevState => ({
      countriesModalOpen: !prevState.countriesModalOpen,
    }));
  };

  renderDetailsAndCountryOptions() {
    return (
      <div className="details-and-country-options">
        {this.renderBalance()}
        {this.renderChangeCountryModalIfNeeded()}
        {this.renderArgentinaDisclaimerIfNeeded()}
      </div>
    );
  }

  renderChangeCountryModalIfNeeded() {
    return (
      (typeof this.props.allowCountryChange === "undefined" || this.props.allowCountryChange) &&
      this.props.countries?.length > 0 && (
        <CountryChangeList
          countries={this.props.countries}
          currentCountry={this.props.currentCountry}
          onChangeCountry={this.onChangeCountry}
          onModalOpen={this.toggleCountriesModalOpen}
        />
      )
    );
  }

  renderArgentinaDisclaimerIfNeeded() {
    return (
      this.props.currentCountry &&
      this.props.currentCountry.code3 === "ARG" &&
      ((this.props.selectedPaymentMethodId.includes("paypal") && <FormattedMessage id="reflected-currency-USD" />) ||
        (this.props.selectedPaymentMethodId.includes("dlocal-creditcard") && (
          <div className="argentina-disclaimer">
            <FormattedMessage id="reflected-currency-local" />
          </div>
        )))
    );
  }

  render() {
    const { paymentOptions, loading, currentCountry, countries } = this.props;
    const hasPaymentOptions = paymentOptions.length > 0;

    return (
      <div className="payment-flow">
        <ProgressBar step={1} />
        {loading ? (
          <div className="container loading">
            <div className="loading-spinner" />
          </div>
        ) : !hasPaymentOptions ? (
          this.renderNoPaymentOptionsAvailable()
        ) : (
          <div className="container">
            {this.renderDetailsAndCountryOptions()}
            {this.renderPricePointView()}
            {this.renderContinueLinks()}
          </div>
        )}
        <CountriesModal
          contentLabel="CountriesModal"
          open={this.state.countriesModalOpen}
          currentCountry={currentCountry}
          countries={countries}
          onChangeCountry={this.onChangeCountry}
          onModalOpen={this.toggleCountriesModalOpen}
          onRequestClose={this.toggleCountriesModalOpen}
        />
      </div>
    );
  }
}

BundleSelection.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  loadPaymentOptions: PropTypes.func.isRequired,
  loadPaymentOptionsPrepaidInline: PropTypes.func,
  loadPurchaseContextIfNeeded: PropTypes.func.isRequired,
  confirmPricePointPurchase: PropTypes.func.isRequired,
  currentCountry: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  sessionToken: PropTypes.string.isRequired,
  pricePointId: PropTypes.string,
  storefrontAccountCode: PropTypes.string.isRequired,
  minVirtualAmount: PropTypes.number,
  checkoutUrl: PropTypes.string,
  selectedPaymentMethodId: PropTypes.string,
  countries: PropTypes.array.isRequired,
  isKorea: PropTypes.bool.isRequired,
  purchaseContext: PropTypes.object,
  useApiV2: PropTypes.bool.isRequired,
  hasDoubleBonusVc: PropTypes.bool,
  purchaseCurrency: PropTypes.string
};

const mapStateToProps = (state) => ({
  paymentOptions: getPaymentOptionsView(state),
  currentCountry: getCurrentCountry(state),
  loading: getOptionsLoadingState(state),
  sessionToken: getSessionToken(state),
  sessionPricePointId: getSessionPricePointId(state),
  storefrontAccountCode: getStorefront(state),
  storeCode: getSessionStoreCode(state),
  minVirtualAmount: getMinVirtualAmount(state),
  checkoutUrl: getCheckoutUrl(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  selectedPricePointId: getSelectedPricePointId(state),
  countries: getStorefrontCountries(state),
  allowCountryChange: getAllowCountryChange(state),
  taxDisclaimer: getTaxDisclaimer(state),
  isKorea: getSessionIsKorea(state),
  purchaseContext: getPurchaseContext(state),
  useApiV2: getSessionUseApiV2(state),
  purchaseCurrency: getPurchaseCurrency(state)
});

BundleSelection.contextTypes = {
  router: PropTypes.object
};

export default connect(mapStateToProps, {
  loadPaymentOptions,
  loadPaymentOptionsV2,
  loadPaymentOptionsPrepaidInline,
  loadPaymentOptionsPrepaidInlineV2,
  loadPurchaseContextIfNeeded,
  selectPaymentMethod,
  selectPricePoint,
  confirmPricePointPurchase,
  confirmPricePointPurchaseV2,
  initSessionPricePointId
})(BundleSelection);
