export function findPricePointForId(paymentOptions, pricePointId) {
  return paymentOptions
    .flatMap((paymentOption) => paymentOption.pricePoints)
    .find((pricePoint) => {
      return pricePointId === pricePoint.id;
    });
}

export function getPricePointAmount(pricePoint) {
  if (!pricePoint) {
    return 0;
  }

  const virtualAmount = pricePoint.virtualAmount || 0;
  const virtualBonusAmount = pricePoint.virtualBonusAmount || 0;
  return virtualAmount + virtualBonusAmount;
}

export function getVcPricePointsForPaymentMethod(paymentOptions, paymentMethodId) {
  return paymentOptions
    .filter((option) => option.uniquePaymentMethodId === paymentMethodId)
    .flatMap((option) => option.pricePoints)
    .filter((pricePoint) => !!pricePoint.virtualCurrencyCode); // SKU price points not supported for selection
}
